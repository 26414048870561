<template>
  <div class="chatroom">
    <van-button type="success">开始使用</van-button>
  </div>
</template>
<script>
export default {
  name: "RobotStart",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
    };
  },
  mounted() {
    console.log("启动页");
  },
  activated() {
    console.log("启动页");
  },
  methods: {
    init() {
      console.log("启动页");
    }

  }
};
</script>
<style lang="less">

</style>
